// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2018, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

import './public-path'
import computeBasePath from 'Uif/apps/computeBasePath'

async function boot() {
  await import('Uif/core/token').then(loadedModule => {
    loadedModule.checkForTokenInSession('manager')
  })

  if (window.apiConnectCfg && window.apiConnectCfg.formFactor === 'icp4i') {
    // location to load admin locale files and for other dynamic imports. This is needed here since
    // setting through public-path doesn't work on zen production stack
    __webpack_public_path__ = `${computeBasePath()}/`
  }
  /* In order to provide translation anywhere/anytime, setIntl must be called before importing app components*/
  // In order to support the React Module Federation, we need to delay the loading react-intl inside
  // boot function (instead of using the import at top of this file) and then call setIntl after
  // the react-intl module is loaded.
  await import('Uif/lib/i18n/react-intl').then(loadedModule => {
    loadedModule.setIntl()
  })

  /* Load and execute whatever is needed before rendering the app*/

  /* Import BaseApp and start rendering */

  const managerImportPromise = import(
    /* webpackMode: "lazy", webpackChunkName: "managerApp" */
    './Manager'
  )
  const renderAppImportPromise = import(
    /* webpackMode: "lazy", webpackChunkName: "commonEntry" */
    'Apps/commonEntry'
  )

  Promise.all([renderAppImportPromise, managerImportPromise])
    .then(resolvedImports => {
      const renderApp = resolvedImports[0].default
      const manager = resolvedImports[1].default

      renderApp(manager)
    })
    .catch(error => {
      try {
        // Try reloading the page, but not more than once.

        if (window.localStorage.getItem('manager-reloaded-once') !== 'true') {
          console.error(`Error loading manager app (retrying once): ${error}`)
          window.localStorage.setItem('manager-reloaded-once', 'true')
          window.location.reload()
        } else {
          console.error(`Error loading manager app (not retrying): ${error}`)
          throw error
        }
      } catch (localStorageError) {
        // If we can't access local storage, just surface the original error.
        console.error(
          `Error loading manager app (no local storage access, not retrying): ${error}`
        )
        throw error
      }
    })
}
boot().catch(console.error)
